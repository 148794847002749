import Button from "@components/blocks/Button";
import Container from "@components/common/Container";
import SimpleRichText from "@components/common/SimpleRichText";
import StrapiImage from "@components/common/StrapiImage";
import { Check, Down } from "@icon-park/svg";
import { Icon, Title } from "@justhome/react-ui";
import {
  ComponentBlocksListItemTwo,
  ComponentSectionsHeroV3,
  Maybe,
} from "@lib/generated/graphql";
import clsx from "clsx";
import { FC, MouseEventHandler, useId, useState } from "react";

interface HeroV3Props {
  data: ComponentSectionsHeroV3;
}

interface ListItemTwoProps {
  data: ComponentBlocksListItemTwo;
  isOpen: boolean;
  onClick: MouseEventHandler;
}

const ListItemTwo: FC<ListItemTwoProps> = ({ data, isOpen, onClick }) => {
  const id = useId();
  const buttonId = useId();
  return (
    <li
      className={clsx("rounded-s py-3xs px-2s", {
        "bg-neutral-12": isOpen,
      })}
    >
      <button
        id={buttonId}
        aria-controls={id}
        aria-expanded={isOpen}
        onClick={onClick}
        className="text-body-1 font-semibold w-full text-left flex items-center hover:text-primary-825 focusable rounded-s"
      >
        <Icon icon={Check} className="!text-primary-800 mr-2xs" size="24" />
        {data.title}
        <Icon
          icon={Down}
          size="16"
          className={clsx("ml-xs", {
            "rotate-180": isOpen,
          })}
        />
      </button>
      <SimpleRichText
        id={id}
        aria-labelledby={buttonId}
        className={clsx("mt-xs overflow-hidden pl-m", {
          "h-none": !isOpen,
          "h-auto": isOpen,
        })}
      >
        {data.description}
      </SimpleRichText>
    </li>
  );
};

interface ListItemTwoAccordionProps {
  data?: Maybe<Maybe<ComponentBlocksListItemTwo>[]>;
  className?: string;
}

const ListItemTwoAccordion: FC<ListItemTwoAccordionProps> = ({
  data,
  className,
}) => {
  const [openIndex, setOpenIndex] = useState<number>(0);

  return (
    <ul className={clsx("space-y-2xs", className)}>
      {data?.map(
        (item, index) =>
          item && (
            <ListItemTwo
              key={item?.id}
              data={item}
              isOpen={openIndex === index}
              onClick={() => setOpenIndex(index)}
            />
          ),
      )}
    </ul>
  );
};

const HeroV3: FC<HeroV3Props> = ({ data }) => {
  return (
    <div className="relative m:py-3m">
      <div className="relative m:absolute inset-none w-full pb-[60%] m:pb-none">
        <StrapiImage
          fill
          loading="eager"
          sizes="(min-width: 1560px) 100vw, 1px"
          quality={85}
          image={data.widescreenImage}
          className={clsx(
            "hidden l:block object-cover absolute inset-none w-full h-full",
          )}
        />

        <StrapiImage
          fill
          loading="eager"
          sizes="(min-width: 960px) and (max-width: 1560px) 100vw, 1px"
          quality={85}
          image={data.image}
          className={clsx(
            "hidden m:block l:hidden object-cover absolute inset-none w-full h-full",
          )}
        />

        <StrapiImage
          fill
          loading="eager"
          sizes="(max-width: 960px) 100vw, 1px"
          quality={85}
          image={data.mobileImage}
          className={clsx("w-full -mb-m m:hidden")}
        />
      </div>
      <Container>
        <div className="z-10 relative grid grid-cols-12 m:px-s l:px-3xl">
          <div className="flex flex-col bg-neutral-0 px-s py-m m:p-m l:p-3m col-span-12 m:col-span-6 rounded-m m:border m:border-neutral-50">
            <Title as="h1" className="text-d3 m:text-d2 mb-s">
              {data.title}
            </Title>
            <SimpleRichText className="text-body-1">
              {data.description}
            </SimpleRichText>
            {data.list && (
              <ListItemTwoAccordion
                data={data.list}
                className="order-last m:order-none mt-xl m:mt-3s"
              />
            )}
            {data.button && (
              <Button
                className="m:mt-2m !w-full"
                {...data.button}
                prefetch={false}
              />
            )}
            <div className="flex mt-2s items-center m:mt-s">
              <p className="hidden l:block text-body-2 max-w-[240px] mr-m">
                {data.trustSealsTitle}
              </p>
              {data.trustSeals?.data && (
                <div className="flex gap-3s flex-none">
                  {data.trustSeals.data.map((image) => (
                    <div key={image.attributes?.url}>
                      <StrapiImage
                        priority
                        image={{ data: image }}
                        className="max-h-3m w-auto"
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeroV3;
