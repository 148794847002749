import clsx from "clsx";
import { ElementType } from "react";

interface DefaultSimpleRichTextProps<T extends ElementType> {
  children?: string | null;
  className?: string;
  as?: T;
}

type SimpleRichTextProps<T extends React.ElementType> =
  DefaultSimpleRichTextProps<T> &
    Omit<
      React.ComponentPropsWithoutRef<T>,
      keyof DefaultSimpleRichTextProps<T>
    >;

const SimpleRichText = <T extends ElementType>({
  children,
  className,
  as,
  ...props
}: SimpleRichTextProps<T>) => {
  if (!children) {
    return null;
  }
  const Component: React.ElementType = as || "div";

  return (
    <Component
      {...props}
      className={clsx(
        className || "text-body-1",
        "[&_a]:text-primary-800 [&_a:hover]:text-primary-850",
      )}
      dangerouslySetInnerHTML={{ __html: children }}
    ></Component>
  );
};

export default SimpleRichText;
